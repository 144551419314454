import axios from "axios";
axios.defaults.baseURL = "https://easyco.kopsyahmsi.com/api/api/";

/**
 * BEGIN
 * @author Irham C. <icip1998@gmail.com>
 * @date 2023-10-21
 */
export default {
    listReportTransaksiTabungan(payload, token) {
    let url = "laporan/list/transaksi_tabungan";
    let config = {
      headers: {
        token: token,
      },
    };
    return axios.post(url, payload, config);
  },
  listReportTransaksiTabunganExportToXLSX(payload, token) {
        const url = "laporan/list/excel/transaksi_tabungan?" + payload;
        const config = {
            headers: {
                token: token,
            },
            responseType: 'blob'
        };
        return axios.get(url, config);
    },
    listReportTransaksiTabunganExportToCSV(payload, token) {
        const url = "laporan/list/csv/transaksi_tabungan?" + payload;
        const config = {
            headers: {
                token: token,
            },
            responseType: 'blob'
        };
        return axios.get(url, config);
    },
};