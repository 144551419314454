import axios from "axios";
axios.defaults.baseURL = "https://easyco.kopsyahmsi.com/api/api/";

/**
 * BEGIN
 * @author Irham C. <icip1998@gmail.com>
 * @date 2023-11-04
 */
export default {
    prosesPenutupanTabungan(payload, token) {
        const url = "tabungan/verifikasi_tutup";
        const config = {
            headers: {
                token: token,
            },
        };
        return axios.post(url, payload, config);
    },
};